import React, { createContext, useReducer } from 'react';
import RecordReducer from './RecordReducer';
import InitialState from './InitialState';

export {actions as Actions} from './RecordReducer'; //For simpler importing in other areas

export const AppContext = createContext(InitialState);

export const ContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(RecordReducer, InitialState);
    return <AppContext.Provider value={{ state, dispatch }} >{children}</AppContext.Provider>
}
