import LocalStore from "../../Utils/LocalStore";
import uuid from 'uuid/v4';

const localStore = new LocalStore('prtrack');

const defaultPRs = [
    {
        id: uuid(),
        activity: "Flat bench press",
        unitType: "weight",
        unit: "lbs",
        goal: "315",
        history: [
            {value: "285", date: "2019-10-10"},
        ]
    },
    {
        id: uuid(),   
        activity: "1-mile run",
        unitType: "speed",
        unit: "seconds",
        goal: "420",
        history: [
            {value: "420", date: "2020-04-15"},
        ]
    },
    {
        id: uuid(),
        activity: "Pull-ups (single set)",
        unitType: "reps",
        unit: "reps",
        goal: "15",
        history: [
            {value: "12", date: "2020-05-26"},
        ]
    }
]

//TODO make constants for data keys like "recordData"
let localPRs = localStore.read('recordData');

if(!localPRs){
    localStore.create('recordData', defaultPRs);
    localPRs = localStore.read('recordData');
}
const PRs = localPRs;


 const InitialState = {
    recordData: PRs,
    searchTerm: localStore.read('searchTerm', ''),
};

export default InitialState;
