
//TODO allow for nested keys "e.g. get('records/prs/1')" 

function LocalStore(namespace) {
    this.namespace = namespace;
}

LocalStore.prototype = {
    create: function (key, data) {
        localStorage.setItem(`${this.namespace}/${key}`, JSON.stringify(data));
    },

    /**
     * Get record by key
     * 
     * @param {array} key 
     */
    read: function(key, fallback) {
        //TODO add checks on key
        const storeData = localStorage.getItem(`${this.namespace}/${key}`);

        if(null === storeData){
            return fallback;
        }

        return JSON.parse(storeData);
    },

    /**
     * Update records that match the given key
     * 
     * @param {object} key 
     * @param {object} data 
     */
    update: function (key, data) {
        localStorage.setItem(`${this.namespace}/${key}`, JSON.stringify(data));
    },

    /**
     * TODO
     * 
     * @param {*} filter 
     */
    delete: function (filter) {
        // delete records that match the given filter
    }

}

export default LocalStore;