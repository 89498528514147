export default (state, action) => {
    switch (action.type) {
        case 'TOGGLEMENU':
            return { ...state, menuOpen: !state.menuOpen };
        case "LOGIN": {
            localStorage.setItem("isLoggedIn", JSON.stringify(action.payload.isLoggedIn))
            localStorage.setItem("user", JSON.stringify(action.payload.user))
            console.log(action.payload.isLoggedIn)
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                user: action.payload.user
            };
        }
        case "LOGOUT": {
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("user");
            return {
                ...state,
                isLoggedIn: false,
                user: null
            };
        }
        default:
            return state
    }
}