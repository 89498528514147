import { Container, Divider, Grid, MenuItem, Select, Tab, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PetFormModal from './PetForm';
import WeightForm from './WeightForm';
import uuid from 'uuid/v4';
import {LocalStore, roundNumber} from '../Utils';
import TabBar from './TabBar';
import TabPanel from './TabPanel';
import PetDetails from './PetDetails';
import PetHistory from './PetHistory';
import './app.css';

const localStore = new LocalStore('petweight');

const initialState = {
    userId: null,
    pets: localStore.read('pets', {}),
    selectedPet: localStore.read('selectedPet', 0)
};

const defaultPet = {
    name: "",
    type: "dog",
    idealWeight: 30,
    weights: []
};

const App = (props) => {
    const [state, setState] = useState(initialState);
    const [tabState, setTabState] = useState(0);

    const getCurrentPet = (key) => {
        let currentPet = defaultPet;
        if(state.selectedPet && state.pets[state.selectedPet]){
            currentPet = state.pets[state.selectedPet];
        }

        return currentPet;
    }

    useEffect(() => {
        localStore.update('pets', state.pets);
    }, [state.pets]);

    useEffect(() => {
        localStore.update('selectedPet', state.selectedPet);
    }, [state.selectedPet]);

    const toggleTab = (tabIndex) => {
        setTabState(tabIndex);
    };

    const addPet = ({name, type, idealWeight}) => {
        let newPetId = uuid();
        let newPet = {
            id: newPetId,
            name,
            type,
            idealWeight,
            weights: [],
            createdTime: Date.now()
        };
        setState({...state, selectedPet: newPetId, pets: {...state.pets, [newPetId]: newPet}});
    }

    const addWeight = ({personWeight, totalWeight}) => {
        let currentPet = getCurrentPet();
        if(!currentPet.id){
            alert('You have to pick a pet first');
            return false;
        }

        let newWeight = {
            id: uuid(),
            petId: state.selectedPet,
            totalWeight,
            personWeight,
            petWeight: roundNumber(totalWeight - personWeight, 2),
            createdTime: Date.now()
        }
        let updatedPet = {...currentPet, weights: [...currentPet.weights, newWeight ]}
        setState({...state, pets: {...state.pets, [state.selectedPet]: updatedPet }});
        
        return true;
    }

    return (
        <Container>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" align="center">Pet Weight Tracker</Typography>
            </Grid>
            <Grid item xs={8} align="center">
                <Select
                    name="selectedPet"
                    className="form-control"
                    value={state.selectedPet}
                    onChange={(e) => {setState({...state, selectedPet: e.target.value})}}
                >
                {
                    state.pets && Object.keys(state.pets).length
                    ? Object.keys(state.pets).map((key) => {
                        return(<MenuItem key={key} value={key}>{state.pets[key].name}</MenuItem>)
                    })
                    : <MenuItem key={0} value={0}>---- Add A Pet ----</MenuItem>
                }
                </Select>
            </Grid>
            <Grid item xs={4} align="center">
                <PetFormModal submitAction={addPet} />
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <WeightForm
                    submitAction={addWeight}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <TabBar active={tabState}>
                    <Tab label="Details" index={0} active={tabState} onClick={toggleTab.bind(null, 0)} style={{width: '50%'}}/>
                    <Tab label="History" index={1} active={tabState} onClick={toggleTab.bind(null, 1)} style={{width: '50%'}}/>
                </TabBar>
                <TabPanel active={tabState} index={0}>
                    <PetDetails pet={state.pets[state.selectedPet] || {}}/>
                </TabPanel>
                <TabPanel active={tabState} index={1}>
                    <PetHistory pet={state.pets[state.selectedPet] || {}}/>
                </TabPanel>
            </Grid>
        </Grid>
        </Container>
    );
}

export default App;