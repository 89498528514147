import React from 'react';
import { Grid, Typography, makeStyles, Button } from '@material-ui/core';
import './hero.css';
import { Link } from 'react-router-dom';

export const PrTrackerHero = (props) => {
    const {bgOpacity} = props || '.75';
    const bgImage = '/images/prtracker-banner.jpeg';
    const unique = Math.random().toString(36).substr(2, 9)

    return (
        <Grid item xs={12} className="hero-background prtracker" >
            <style>
                {`
                .hero-background.${unique}::before {
                }
                `}
            </style>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h2" align="center" className="hero-title">
                        Personal Record Tracker
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} align="center">
                    <Typography variant="h5" align="center" className="hero-subtitle">
                        Track personal records in all sorts of activities. You define the activity, set a goal, and track every time you hit a PR!
                    </Typography>
                    <Link to="/prtracker" class="hero-button"><Button variant="contained" color="primary">Try it out</Button></Link>
                </Grid>
            </Grid>
        </Grid>
    );
}

export const PetWeightHero = (props) => {
    return (
    <Grid item xs={12} className="hero-background petweight">
        <Grid container direction="row-reverse">
            <Grid item xs={12} sm={6} align="center">
                <Typography variant="h2" align="center" className="hero-title">
                    Pet Weight Logger
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} align="center">
                <Typography variant="h5" align="center" className="hero-subtitle">
                    Easily calculate your pets weight and keep a log to track their weight change over time.
                </Typography>
                <Typography variant="h5" align="center" className="hero-subtitle">
                    Supports both types of pets: dogs and cats!
                </Typography>
                <Link to="/petweight" class="hero-button"><Button variant="contained" color="primary">Get Started</Button></Link>
            </Grid>
        </Grid>
    </Grid>
    );
}
