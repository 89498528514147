import React, { Component } from 'react';
import { Typography, Container} from '@material-ui/core';


export class About extends Component {
    render() {
        return (
            <Container>
                <Typography variant="h2">About Page</Typography>
                <Typography variant="body1" gutterBottom>
                    This "About" is going to be about this website. It's motivation, the steps behind creating it, and the learning along the way.
                </Typography>
                
                <Typography variant="h4">Motivation</Typography>
                <Typography variant="body1" gutterBottom>
                    I was a web developer in 2020 and I did not have a personal website.
                    I always meant to, and at this point it was getting emabarassing. Every job related form I filled out, seemed to have a field for "website", which I continuously left empty.
                    So it was time.
                    One of the best things about software development is that there is always something new to learn. I when that learning is voluntary, its actually really fun.
                    So I needed to make a website, I wanted it to be cheap to run, and I wanted to learn cool technology in the process (not just spin up a Squarespace).
                </Typography>

                <Typography variant="h4">Serverless</Typography>
                <Typography variant="body1" gutterBottom>
                    Now I know this isn't bleeding edge in 2020. It's a term I had heard alot, and always meant to investigate.
                    It sounds interesting, but I think I just never grocked it enough to dig in any further than a surface level abstraction that went over my head.
                    Finally, for work, I voluntold to build a small Amazon integration for our app.
                    This exposed me to the world of Amazon APIs/SDKs, various Amazon Serivices, and most importantly, finally figured out the power of Lambda.
                    I got it now; this whole serverless thing was more possible now and I started down the road to learn serverless.
                    Along the journey I learned I could serve a static site on S3 for quite cheap.
                    But static site aren't cool. But React is; and I could build a full-fledged web site/app with React and some other Amazon services.
                    Too bad I didn't know React ...
                </Typography>

                <Typography variant="h4">React</Typography>
                <Typography variant="body1" gutterBottom>
                    But I did know some JavaScript, and I had played around with Angular and some other JS frameworks. How hard could it be.
                    It actually seemed like it would be pretty easy. It's a whole new paradigm than the jQuery soup I wrote at the start of my career.
                    This new pardigm, with the importing and the modules, was much closer to the code structure I'm more used to in backend applications.
                    Not to mention, very good documentation, and no shortage of courses and YouTube videos.
                    So as I'm writing this I'm still working on getting it built out. Maybe I'll eventually be able to write som coherent posts about what I learned.
                </Typography>

                <Typography variant="h4">Deployment</Typography>
                <Typography variant="body1" gutterBottom>
                    Another time when I was tasked with something out of my wheelhouse,I was asked to build a GitHub app in NodeJs.
                    This was cool, I had played around with Node a bit, and I had always meant to learn what people meant when they talked about Github "webhooks".
                    During this process I learned a good deal more about Node and way more about Github app and webhooks.
                    I was starting to get a picture a full pipeline build.
                    Maybe something like, git push, triggers a Lambda, that builds a React app, and pushes it to S3 where it served as a static site.
                    That just might work. It's still in the process while I try to wrestle with nodejs serverless, lambda and S3.
                </Typography>

                <Typography variant="h4">Putting it Together</Typography>
                <Typography variant="body1" gutterBottom>
                    By bulding this website, I can accomplish quite a few things.
                    Learn some practical knowledge of some modern, popular tech (serverless, Node, React, etc.).
                    Finally have a personal website like all the cool people.
                    And most importantly, have a place I can host my app ideas.

                    That last point is really what this is all about. I constantly have ideas for small web apps.
                    I always want to build these small apps for things I enjoy (I'll explain later), that I could make exactly how I wanted since I was building it.
                    It was so daunting, I need to set up a server, write the backend, then muddle my way through JavaScript and CSS.
                    However, I think now with some time and effort I can learn some new stuff and build a sweet site. 
                </Typography>
            </Container>
        )
    }
}
 
export default About
