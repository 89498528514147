import React from 'react';
import SearchForm from './Form/SearchForm';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        paddingBottom: "2rem",
    },
});

const Heading = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography className={classes.title} variant="h4" align="center">
                Personal Record Tracker
            </Typography>
            <SearchForm/>
        </React.Fragment>
    )
}

export default Heading;