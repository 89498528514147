import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SiteMenu from './Components/SiteMenu';
import Header from './Components/Header';
import { GlobalProvider } from './context/AppState';
import Maintenance from './Components/Maintenance';
import Footer from './Components/Footer';
import MainBody from './Components/MainBody';

const App = () => {
  const state = {
    maintence: false
  }

  if(state.maintence) return <Maintenance/>;

  return (
    <GlobalProvider>
      <Router>
        <div className="App">
          <Header />
          <SiteMenu />
          <MainBody />
          <Footer />
        </div>
      </Router>
    </GlobalProvider>
  );
}

export default App;
