import React, {useContext} from 'react';
import { AppContext, Actions } from './context/Context';
import {Drawer, Container, Grid, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const AppDrawer = () => {
    const { state, dispatch } = useContext(AppContext);

    const closeDrawer = () => {
        dispatch({type: Actions.CLOSE_FORM});
    }

    const DrawerContents = state.openForm ? state.openForm.component : null;;

    return (
        <Drawer anchor="bottom" open={!!state.openForm} onClose={closeDrawer}>
            <Container style={{maxWidth: "480px", marginBottom: "2rem"}}>
                <IconButton style={{position: "absolute", top: "0", left: "0"}} onClick={closeDrawer}>
                    <CloseIcon/>
                </IconButton>
                <Grid container style={{padding: "25px 10px"}}>
                    <Grid item xs={12}>
                        {
                            state.openForm
                            ? <DrawerContents record={state.openForm.record ?? null}></DrawerContents>
                            : ""
                        }
                    </Grid>
                </Grid>
            </Container>
        </Drawer>
    );
}

export default AppDrawer;
