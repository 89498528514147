import React, { useContext } from 'react';
import { Drawer, List, ListItem, Divider, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import PetsIcon from '@material-ui/icons/Pets';
import InfoIcon from '@material-ui/icons/Info';
import GitHubIcon from '@material-ui/icons/GitHub';
import { GlobalContext } from '../context/AppState';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    logo: {
        width: 200,
        padding: "0 25px",
    }
});

const SiteMenu = (props) => {
    const {state, dispatch} = useContext(GlobalContext);
    const classes = useStyles();

    const toggleMenu = () => {
        dispatch({
            type: "TOGGLEMENU"
        });
    };

    return (
        <Drawer anchor="left" open={state.menuOpen} onClose={toggleMenu}>
            <div
                className="left"
                role="presentation"
                onClick={toggleMenu}
                onKeyDown={toggleMenu}
            >
                <List className={classes.list}>
                    <Link to="/" color="inherit">
                        <img className={classes.logo} src="/images/simple_logo.png" alt="Logo"/>
                    </Link>
                </List>
                <Divider />
                <List className={classes.list}>
                    <Link to="/about">
                        <ListItem button key="About">
                            <ListItemIcon><InfoIcon /></ListItemIcon>
                            <ListItemText primary="About" />
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List className={classes.list}>
                    <Link to="/resume">
                        <ListItem button key="Resume">
                            <ListItemIcon><AssignmentIcon /></ListItemIcon>
                            <ListItemText primary="Resume" />
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List className={classes.list}>
                    <Link to="/petweight">
                        <ListItem button key="PetWeight">
                            <ListItemIcon><PetsIcon /></ListItemIcon>
                            <ListItemText primary="Pet Weight App" />
                        </ListItem>
                    </Link>
                </List>
                <Divider />
                <List className={classes.list}>
                    <Link to="/prtracker">
                        <ListItem button key="PR Tracker">
                            <ListItemIcon><FitnessCenterIcon /></ListItemIcon>
                            <ListItemText primary="PR Tracker" />
                        </ListItem>
                    </Link>
                </List>
                <Divider/>
                <List className={classes.list}>
                    <Link to="/githublogin">
                        <ListItem button key="Login with Github">
                            <ListItemIcon><GitHubIcon /></ListItemIcon>
                            <ListItemText primary="Login with Github" />
                        </ListItem>
                    </Link>
                </List>
            </div>
        </Drawer>
    );

}

export default SiteMenu;