import React, { useState, useContext, useEffect } from 'react';
import { Grid, Button, TextField, FormControl, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import * as moment from 'moment';
import { AppContext, Actions } from '../context/Context';

const today = moment().format('yyyy-MM-DD');

export const NewPrForm = ({ record }) => {
    const { context, dispatch } = useContext(AppContext);
    const initialState = { value: 0, date: today };
    const [pr, setPr] = useState(initialState);

    const submitForm = (event) => {
        event.preventDefault();
        const newRecord = { ...record, history: [...record.history, { value: pr.value, date: pr.date }] };
        dispatch({ type: Actions.UPDATE_RECORD, payload: newRecord });
        dispatch({ type: Actions.CLOSE_FORM });
    }

    return (
        <form onSubmit={submitForm}>
            <Grid container spacing={1} align="center">
                <Grid item xs={12}>
                    <Typography variant="h6">New PR!</Typography>
                    <Typography variant="subtitle1">{record.activity}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id="record"
                        name="value"
                        label="New Record"
                        type="number"
                        value={pr.value}
                        margin="normal"
                        onChange={(e) => setPr({ ...pr, value: e.target.value })}
                    />
                </Grid>
                <Grid item xs={8}>
                    <FormControl>
                        <TextField
                            id="date"
                            name="date"
                            label="Date"
                            type="date"
                            margin="normal"
                            value={pr.date}
                            onChange={(e) => setPr({ ...pr, date: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{padding: "15px 40px"}}>
                    <Button type="submit" color="primary" variant="contained" style={{ height: "100%", width: "100%" }}>
                        Count It!
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default NewPrForm;