import React from 'react';
import { Paper, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    image: {
        width: "12rem",
        borderRadius: "50%",
    },
    container: {
        textAlign: "center",
        paddingBottom: "4rem",
    }
});

const Header = (props) => {
    const classes = useStyles();
    
    return (
        <Paper elevation={0} className={classes.container}>
            <img className={classes.image} src="/images/resume/me.jpeg" alt=""/>
            <Typography variant="h2">Daniel Renaud</Typography>
            <Typography variant="h4">Software Engineer</Typography>
        </Paper>
    );
}

export default Header;
