import React, { useContext, useState } from 'react';
import { Typography, TableRow, TableCell, IconButton, Avatar } from '@material-ui/core';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import Moment from 'react-moment';
import { AppContext, Actions } from './context/Context';
import RecordDetail from './RecordDetail';
import { EditActivityForm } from './Form/ActivityForm';
import { NewPrForm } from './Form/NewPrForm';

const iconMap = {
    weight: FitnessCenterIcon,
    speed: DirectionsRunIcon,
    reps: RepeatOneIcon,
};

const Record = (props) => {
    const { state, dispatch } = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const record = props.recordData;

    const getUnitIcon = ({ unitType }) => {
        const Icon = iconMap[unitType];
        return <Icon />;
    }

    const getLatestRecord = ({ history }) => {
        return history.reduce((a, b) => a.date > b.date ? a : b);
    }

    const getBestRecord = ({ history, unit, unitType }) => {
        let best = {value: 0, date: new Date()};
        if(history && history.length){
            best = history.reduce((a, b) => {
                let numA = parseInt(a.value, 10);
                let numB = parseInt(b.value, 10);
                if (unitType === 'speed') {
                    return numA < numB ? a : b
                }
                return numA > numB ? a : b
            });
        }
        return { ...best, unit };
    }

    const formatRecord = (value, unit) => {
        let formatted = value;

        const pad = (val, num = 2) => {
            let strValue = `${val}` ?? '0';
            let zeros = new Array(Math.max(num - strValue.length, 0)).fill(0);
            return zeros.join('') + strValue;
        }

        //TODO get more dynamic list of type/unit mapping
        if ('seconds' === unit) {
            let hour, min, sec = 0;
            formatted = '';

            // Hour
            let remaining = value;
            hour = Math.floor(value / 3600);
            formatted += hour > 0 ? Math.floor(value / 3600) + ':' : '';
            // Minute
            remaining -= (hour * 3600);
            min = Math.floor(remaining / 60)
            formatted += hour > 0 ? pad(min) : min + ':';
            // Second
            remaining -= (min * 60);
            sec = remaining % 60;
            formatted += hour <= 0 ? pad(sec) : ''; //only show seconds if under an hour
        }

        return formatted;
    }

    const deleteActivity = () => {
        alert('TODO add confirmation!!!');
        dispatch({ type: Actions.DELETE_RECORD, payload: record });
    }

    const openEditForm = () => {
        dispatch({type: Actions.OPEN_FORM, payload: {component: EditActivityForm, record: record}});
    }

    const openNewPrForm = () => {
        dispatch({type: Actions.OPEN_FORM, payload: {component: NewPrForm, record: record}});
    }

    return (
        <React.Fragment>
            <TableRow style={{borderTop: "3px solid #ccc"}}>
                <TableCell style={{paddingRight: 0, paddingLeft: "5px"}}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/> }
                    </IconButton>
                </TableCell>
                <TableCell component="th" style={{padding: "5px"}}>
                    <Typography variant="h6" onClick={() => setOpen(!open)}>
                        {getUnitIcon(record)}&nbsp;{record.activity}
                    </Typography>
                </TableCell>
                <TableCell align="center" style={{padding: "5px"}}>
                    {/* TODO make color vary by distance from goal */}
                    <Avatar variant="rounded" style={{backgroundColor: "blue", width: "auto"}}>
                        {formatRecord(getBestRecord(record).value, record.unit)}
                    </Avatar>
                    <Typography variant="caption">
                        <Moment format="MMM DD" parse="yyyy-MM-DD">
                            {getBestRecord(record).date}
                        </Moment>
                    </Typography>
                </TableCell>
                <TableCell align="center" style={{padding: "5px"}}>
                    <Avatar variant="rounded" style={{backgroundColor: "#66CD00", width: "auto"}}>
                        {formatRecord(record.goal, record.unit)}
                    </Avatar>
                    <Typography variant="caption">Goal</Typography>
                </TableCell>
                <TableCell align="center" style={{padding: "5px"}}>
                    <AddAlertIcon color="action" fontSize="large" onClick={openNewPrForm}/>
                </TableCell>
            </TableRow>
            <RecordDetail open={open} record={record} deleteAction={deleteActivity} editAction={openEditForm}/>
        </React.Fragment>
    );
}

export default Record;
