import { Grid, TextField, Button, Drawer, Container, Typography, Select, InputLabel, FormControl, MenuItem, InputAdornment } from '@material-ui/core';
import React, { useState } from 'react';

const PetForm = ({submitAction, closeModal}) => {
    const [state, setState] = useState({name: "", type: "dog", idealWeight: "30"});

    const handleChange = (event) => {
        let {name, value} = event.target;
        setState({...state, [name]: value})
    }

    return (
        <Container className='drawer-container'>
            <Grid container spacing={3}>
                <Grid item xs={12} align="center">
                    <Typography variant="h5">Add A Pet</Typography>
                </Grid>
                
                <Grid item xs={1}></Grid>
                <Grid item xs={10} align="left">
                    <TextField 
                        className="form-control full-width"
                        name="name"
                        onChange={handleChange}
                        label="Pet Name"
                        value={state.name}
                    />
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={10} align="left">
                    <Grid container>
                        <Grid item xs={5}>
                            <FormControl className="form-control">
                                <InputLabel>Pet Type</InputLabel>
                                <Select
                                    value={state.type}
                                    onChange={handleChange}
                                    name="type"
                                >
                                    <MenuItem value="dog">
                                        Dog
                                    </MenuItem>
                                    <MenuItem value="cat">
                                        Cat
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                            <TextField
                                className="form-control"
                                label="Ideal Weight"
                                name="idealWeight"
                                onChange={handleChange}
                                type="number"
                                value={state.idealWeight}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">pounds</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={10} align="center">
                    <Button className="form-control" variant="contained" color="primary"
                        onClick={() => { submitAction(state); closeModal(); }}
                    >
                        Add
                    </Button>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </Container>
    );
}

const PetFormModal = ({submitAction}) => {
    const [state, setState] = useState({open: false});

    const open = () => {
        setState({...state, open: true});
    }
    const close = () => {
        setState({...state, open: false});
    }

    return (
        <React.Fragment>
            <Button onClick={open} variant="contained" color="primary">New Pet</Button>
            <Drawer className="drawer" anchor='bottom' open={state.open} onClose={close}>
                <PetForm submitAction={submitAction} closeModal={close}/>
            </Drawer>
        </React.Fragment>
    );
}

export default PetFormModal;
