import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import * as moment from 'moment';
import {roundNumber} from '../Utils';

const WeightRow = ({weight}) => {
    return (
        <TableRow>
            <TableCell>
                {moment(weight.createdTime).format('MMM DD, YYYY')}
            </TableCell>
            <TableCell>
                {roundNumber(weight.petWeight)}
            </TableCell>
            <TableCell>
                {roundNumber(weight.personWeight)}
            </TableCell>
            <TableCell>
                {roundNumber(weight.totalWeight)}
            </TableCell>
        </TableRow>
    );
}

const PetHistory = ({pet}) => {
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} align="center">
                <Typography variant="h5">{pet.name || 'Pet'} History</Typography>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Pet</TableCell>
                                <TableCell>Person</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                pet.weights && pet.weights.length
                                ? pet.weights.reverse().map((weight, index) => <WeightRow weight={weight} key={index}/>)
                                : <TableRow><TableCell align="center" colSpan={4}>No Records Found</TableCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

export default PetHistory;