import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import {LandingPageWrapper} from './Wrappers';
import {PrTrackerHero, PetWeightHero} from './Components/Hero';

const useStyles = makeStyles((theme) => ({
    banner : {
        width: "100%",
        height: "360px",
        backgroundImage: "url('/images/computer-banner.jpeg')",
        backgroundRepeat: "none",
        backgroundSize: "cover",
        backgroundPosition: "bottom center",
        position: "relative"
    },
    bannerTitle: {
        position: "absolute",
        left: "50px",
        top: "0px",
        color: '#D3D3D3'
    },
    about: {
        padding: "30px",
    },
    logoContainer:{
        textAlign: "center",
        maxHeight: "300px",
        padding: "30px"
    },
    logo: {
        borderRadius: "50%",
        height: "100%",
    },
}));

const Home = (props) => {
    const classes = useStyles();

    return (
        <LandingPageWrapper>
            <Grid container>
                <Grid item xs={12} className={classes.banner}>
                </Grid>

                <Grid item xs={12} sm={4} className={classes.logoContainer}>
                    <img className={classes.logo} alt="Dan Renaud" src="/images/simple_logo.png"/>
                </Grid>
                <Grid item xs={12} sm={8} className={classes.about} align="center">
                    <Typography variant="h6">
                        My name is Dan, I'm a software developer and this is my website.
                        <br/>It's serverless, which means it exists nowhere... and everywhere.
                        <br/>
                        <br/>
                        Sure, there are easier ways to make a prettier site in less time.
                        <br/>But you know what this site has... potential!
                    </Typography>
                </Grid>
                
                <PrTrackerHero/>
                <PetWeightHero/>
            
            </Grid>
        </LandingPageWrapper>
    );
}

export default Home
