
const jobs = [
    {
        company: "Magento, an Adobe Comany",
        logo: "https://www.thecoder.de/wp-content/uploads/2020/02/magento2-logo-an-adobe-company.png",
        smallLogo: "/images/resume/magento_small_logo.png",
        jobTitle: "Software Engineer",
        dates: "July 2017 - Present",
        responsibilities: [
            "Implement GraphQL API layer across multiple domains",
            "Investigate and resolve bugs, and write comprehensive automated tests",
            "Perform code reviews and QA to ensure product quality",
        ]
    },
    {
        company: "CGG",
        logo: "https://www.cgg.com/imgs/logo.png",
        smallLogo: "/images/resume/cgg_small_logo.png",
        jobTitle: "Software Engineer",
        dates: "Oct. 2015 - July 2017",
        responsibilities: [
            "Develop interactive user interfaces focused on User Experience",
            "Write complex server-side code utilizing MVC frameworks as well as plain PHP",
            "Collaborate with users to understand requirements and design innovative solutions to meet user demands"
        ]
    },
    {
        company: "DC Energy",
        logo: "https://images.squarespace-cdn.com/content/53c02ec4e4b0fd3182dd5e06/1405105106215-RSM25UM41RPTIDO4DUGN/fullcolor.jpg",
        smallLogo: "/images/resume/dc_energy_small_logo.png",
        jobTitle: "PHP Developer",
        dates: "Sep. 2014 - Aug. 2015",
        responsibilities: [
            "Design relational schema to for various data used by analysts (MySQL).",
            "Write PHP scripts that scrape data files from external sources, and parse them to fit relational database models.",
            "Contribute to and improve internal framework used for scraping, parsing, and handling data"
        ]
    },
    {
        company: "American Council for International Education",
        logo: "https://www.americancouncils.org/sites/default/files/AC%20Stacked%20Logo_Color.png",
        smallLogo: "/images/resume/acie_small_logo.png",
        jobTitle: "IT Assistant",
        dates: "Nov. 2012 - Aug. 2014",
        responsibilities: [
            "Administer Ubuntu Linux server enviroment using Linux tools and scripting (primarily Bash and Python)",
            "Work with several database technologies including MSSQL Server, MySQL, and MS Access to collect and manage large data collections",
            "Build and maintain frontend tools for users to enter, view, and organize data",
            "Web development projects using Drupal and PHP"
        ]
    }
];

export default jobs;