import React, { useState } from 'react';
import { TableRow, TableCell, Collapse, Box } from '@material-ui/core';
import HistoryForm from './Form/HistoryForm';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';


const RecordDetail = (props) => {
    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={props.open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <span style={{position: "absolute", display: (props.open ? "" : "none") }}>
                            <EditIcon color="action" onClick={props.editAction}/>
                            &nbsp;
                            <DeleteIcon color="action" onClick={props.deleteAction}/>
                        </span>
                        <br/>
                        <HistoryForm record={props.record}/>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}

export default RecordDetail;
