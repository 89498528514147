import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

const initialState = {
    dev_mode: process.env.REACT_APP_DEV_MODE ?? false,
    menuOpen: false,
    isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn")) || false,
    user: JSON.parse(localStorage.getItem("user")) || null,
    auth:  {
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        proxy_url: process.env.REACT_APP_PROXY_URL,
    } 
}

//Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    return (<GlobalContext.Provider value={{state, dispatch}}>
        {children}
    </GlobalContext.Provider>)
}
