import { makeStyles } from "@material-ui/core";

const useStyles = (area) => {
    return makeStyles((theme) => {
        const styles = {
            header: {
                root: {
                    flexGrow: 1,
                },
                menuButton: {
                    marginRight: theme.spacing(2),
                },
                title: {
                    flexGrow: 1
                }
            },
            body: {
                content: {
                    minHeight: "calc(100vh - 200px);",
                },
            },
            footer: {
                wrapper: {
                    height: "100px",
                },
                divider: {
                    margin: "50px 0 10px 0",
                },
                links: {
                },
                linkItem: {
                    display: "inline",
                }
            },
            createForm: {
                control: {},
                modal: {
                    width: "50%",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            },
        }
        return styles[area];
    })();
};

export default useStyles;
