import React, { Fragment } from 'react';
import Joblist from './JobList';
import Header from './Header';

const Resume = (props) => {
    return (
        <Fragment>
            <Header/>
            <Joblist/>
        </Fragment>
    );
}

export default Resume;
