import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper } from '@material-ui/core';

export const PageWrapper = (props) => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12} style={{height: "3rem"}}/>
                <Grid item className="gutter left" xs={false} sm={1} lg={3} />
                <Grid item xs={12} sm={10} lg={6}>
                    {props.children}
                </Grid>
                <Grid item className="gutter right" xs={false} sm={1} lg={3} />
            </Grid>
        </Container>
    );
}


export const LandingPageWrapper = ({children}) => {
    return (
    <Container disableGutters>
        {children}
    </Container>
    );
}

export const AppWrapper = ({children}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

    useEffect(() => {
        setIsMobile(window.innerWidth < 500);
    }, [window.innerWidth, isMobile, setIsMobile]);

    return (
        <Container disableGutters={isMobile}>
        <Grid container>
            <Grid item xs={12} style={{height: "1rem"}}/>
            <Grid item className="gutter left" xs={false} sm={3} lg={4} />
            <Grid item xs={12} sm={6} lg={4}>
                {children}
            </Grid>
            <Grid item className="gutter right" xs={false} sm={3} lg={4} />
        </Grid>
    </Container>
    );
}

