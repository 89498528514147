import React, { useState } from 'react';
import { Typography, makeStyles, Button, Grid, Link } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles({
    container: {
        height: "50%",
    },
    main: {
        height: "30%",
        margin: "0",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -100%)",
        color: blueGrey.A400,
    },
    quoteContainer: {
    },
    quote: {
        fontSize: "1.6rem",
    },
    quoteMark: {
        fontFamily: "Comic-Sans",
        fontSize: "1.5rem",
        color: blueGrey.A100,
        padding: ".5rem",
    },
    info: {
        fontSize: "1rem",
        color: blueGrey.A100,
        padding: ".2rem",
        float: "right"
    },
    author: {
        fontSize: "1rem",
        color: blueGrey.A400
    },
    next: {
        height: "100%",
        textAlign: "center"
    },
    prev: {
        height: "100%",
        textAlign: "center"
    }
});

const Maintenance = () => {
    const classes = useStyles();
    const quotes = [
        { quote: "If I can change. And you can change. Everybody can change.", author: "Rocky Balboa", info: "https://en.wikiquote.org/wiki/Rocky_IV" },
        { quote: `Theirs not to make reply, Theirs not to reason why, Theirs but to do and die: Into the valley of Death Rode the six hundred.`, author: "Alfred Lord Tennyson", info: "https://poets.org/poem/charge-light-brigade" },
        { quote: "If a man knows not which port he sails, no wind is favorable.", author: "Seneca", info: "https://quoteinvestigator.com/2011/11/25/favorable-wind/" },
        { quote: "Use it up, wear it out, make it do, or do without.", author: "Depression Era Slogan" }
    ];

    const [position, setPosition] = useState(0); //Todo make random
    const previous = () => position === 0 ? setPosition(quotes.length - 1) : setPosition(position - 1);
    const next = () => position === (quotes.length - 1) ? setPosition(0) : setPosition(position + 1);

    return (
        <div className={classes.container}>
            <Grid container className={classes.main}>
                <Grid xs={2} sm={1}>
                    <Button onClick={previous} className={classes.prev}>
                        <NavigateBeforeIcon />
                    </Button>
                </Grid>
                <Grid xs={8} sm={10} className={classes.quoteContainer}>
                    <div alignCenter>
                        {
                            quotes[position].info
                                ? <span className={classes.info}>
                                    <Link color="textPrimary" underline="none" target="blank" href={quotes[position].info}>&#9432;</Link>
                                </span>
                                : <span />
                        }
                        <Typography className={classes.quoteMark} display="inline" color="secondary">"</Typography>
                        <Typography className={classes.quote} display="inline">
                            {quotes[position].quote}
                        </Typography>
                        <Typography className={classes.quoteMark} display="inline">"</Typography>
                    </div>
                    <Typography align="right" className={classes.author}>
                        - {quotes[position].author}
                    </Typography>
                </Grid>
                <Grid xs={2} sm={1}>
                    <Button onClick={next} className={classes.next}>
                        <NavigateNextIcon />
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default Maintenance;
