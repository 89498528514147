import React, {useContext} from 'react';
import { Route } from 'react-router-dom';
import {GlobalContext} from '../context/AppState';
import { Container } from '@material-ui/core';
import { HomePage, AboutPage, ResumePage, PrTrackerPage, PetWeightPage } from './Pages';
import GithubLogin from './GithubLogin';
import useStyles from './useStyles';

const MainBody = (props) => {
    const {state, dispatch} = useContext(GlobalContext);
    const classes = useStyles('body');

    return (
        <React.Fragment>
            <Container xs={12} className={classes.content} disableGutters>
                <Route exact path="/">
                    <HomePage />
                </Route>
                <Route exact path="/about">
                    <AboutPage />
                </Route>
                <Route exact path="/resume">
                    <ResumePage />
                </Route>
                <Route path="/prtracker">
                    <PrTrackerPage />
                </Route>
                <Route path="/petweight">
                    <PetWeightPage />
                </Route>
                <Route path="/githublogin">
                    <GithubLogin />
                </Route>
            </Container>
        </React.Fragment>
    );
}

export default MainBody;
