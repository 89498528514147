import React, { useContext } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import Record from './Record';
import {AppContext, Actions} from './context/Context';
import AddIcon from '@material-ui/icons/Add';
import { CreateActivityForm } from './Form/ActivityForm';

const RecordList = () => {
    const {state, dispatch} = useContext(AppContext);

    const openEditForm = () => {
        const openForm = {
            component: CreateActivityForm
        }
        dispatch({type: Actions.OPEN_FORM, payload: openForm});
    }

    const recordElements = [];
    state.recordData
    .filter((record) => {
        if(!state.searchTerm) return true;
        return record.activity.toLowerCase().includes(state.searchTerm.toLowerCase());
    })
    .map((record, i) => {
        recordElements.push(
            <Record recordData={record} key={i}></Record>
        )
    });

    return (
        <TableContainer>
                <Table aria-label="collapsible table">
                        <TableBody>
                            {
                                // All records
                                recordElements
                            }
                            <TableRow style={{height: "3rem", borderTop: "3px solid #ccc"}} align="center">
                                <TableCell colSpan={6} align="center" style={{padding: 0}}>
                                    <Button style={{ height: "100%", width: "100%" }} onClick={openEditForm}>
                                        <AddIcon/>Add New Activity
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                </Table>
            </TableContainer>
    );
}

export default RecordList;
