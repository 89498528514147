import React, { useState, useContext, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableRow, TableHead, Button, Typography } from '@material-ui/core';
import * as moment from 'moment';
import { actions } from '../context/RecordReducer';
import { AppContext } from '../context/Context';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const HistoryForm = (props) => {
    const today = moment().format('yyyy-MM-DD');
    let { dispatch } = useContext(AppContext);
    const [record, setRecord] = useState(props.record);

    const addNewHistoryRow = () => {
        setRecord({ ...record, history: [...record.history, { date: today, value: 0 }] });
    }

    const updateHistory = (key, data) => {
        let updatedHistory = [...record.history.slice(0, key), data, ...record.history.slice(key + 1)];
        setRecord({ ...record, history: [...updatedHistory] });
    }

    const deleteHistoryItem = (key) => {
        let updatedHistory = record.history.filter((item, index) => {
            return index !== key;
        });
        console.log(updatedHistory);
        setRecord({ ...record, history: updatedHistory });
    }

    useEffect(() => {
        dispatch({ type: actions.UPDATE_RECORD, payload: record });
    }, [record, dispatch]);

    return (
        <form>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">Record</TableCell>
                        <TableCell align="left" colSpan={2}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        record.history.map((historyItem, key) => {
                            return <HistoryItem
                                key={Math.random()}
                                index={key}
                                historyItem={historyItem}
                                doUpdate={updateHistory}
                                doDelete={deleteHistoryItem}
                            />
                        })
                    }
                    <TableRow>
                        <TableCell align="center" colSpan={4}>
                            <Button onClick={addNewHistoryRow} style={{ height: "100%", width: "100%", padding: 0 }}>
                                <AddIcon/> New Log Entry
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </form>
    )
}

const HistoryItem = ({ index, historyItem: intialHistoryItem, doUpdate, doDelete }) => {
    const [historyItem, setHistoryItem] = useState(intialHistoryItem)
    const [editMode, setEditMode] = useState(false);

    const dateChange = (event) => {
        setHistoryItem({ ...historyItem, date: event.target.value });
    }

    const valueChange = (event) => {
        setHistoryItem({ ...historyItem, value: event.target.value });
    }

    const enterKeySave = (event) => {
        if(editMode && event.keyCode === 13){
            doUpdate(index, historyItem);
            setEditMode(!editMode);
        };
    }

    const handleClick = () => {
        if(editMode){
            doUpdate(index, historyItem);
        }
        setEditMode(!editMode);
    }

    const deleteHistoryItem = () => {
        let confirmDelete = alert(index + 'Are you sure you want to delete this item? TODO make this better');

        if(true || confirmDelete){
            doDelete(index);
        }
    }

    return (
        <TableRow>
            <TableCell align="center" style={{padding: "8px"}} width="60%">
                {
                editMode
                ? <TextField
                    id="date"
                    type="date"
                    value={historyItem.date}
                    onChange={dateChange}
                    onKeyDown={enterKeySave}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                : <Typography>{historyItem.date}</Typography>
                }
            </TableCell>
            <TableCell align="center" style={{padding: "8px"}}>
                {
                    editMode 
                    ? <TextField
                        id="record"
                        type="number"
                        value={historyItem.value}
                        onChange={valueChange}
                        onKeyDown={enterKeySave}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    : <Typography>{historyItem.value}</Typography>
                }
                
            </TableCell>
            <TableCell style={{padding: "5px"}}>
                    {editMode
                    ? <SaveIcon onClick={handleClick} type="submit" color="action"/>
                    : <EditIcon onClick={handleClick} type="submit" color="action"/>}
            </TableCell>
            <TableCell style={{padding: 0}}>
                    <DeleteIcon onClick={deleteHistoryItem} color="action"/>
            </TableCell>
        </TableRow>
    );
}

export default HistoryForm;
