import { AppBar, Tabs } from '@material-ui/core';
import React from 'react';

const TabBar = ({children, active}) => {

    return (
        <AppBar position="static" color="default">
            <Tabs
                value={active}
            >
                {children}
            </Tabs>
        </AppBar>
    );
}

export default TabBar;
