import { Container, Divider, List, ListItem, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './useStyles';

const Footer = () => {
    const classes = useStyles('footer');

    const LinkItem = ({label, to}) => {
        return (
            <ListItem className={classes.linkItem}>
                <Link to={to}>
                    <Typography display="inline">{label}</Typography>
                </Link>
            </ListItem>
        );
    }

    return (
        <Container className={classes.wrapper}>
            <Divider className={classes.divider}/>
            <List className={classes.links}>
                <ListItem className={classes.linkItem}>
                    <Typography display="inline">&copy; 2020 Dan Renaud.me</Typography>
                </ListItem>
                <LinkItem to="/" label="Home"/>
                <LinkItem to="/resume" label="Resume"/>
                <LinkItem to="/prtracker" label="PR Tracker"/>
                <LinkItem to="/githublogin" label="Login"/>
            </List>
        </Container>
    );
}

export default Footer;
