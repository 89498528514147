import { Box } from '@material-ui/core';
import React from 'react';

const TabPanel = ({children, active, index, other}) => {
    return (
        <div
          role="tabpanel"
          hidden={active !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {active === index && (
            <Box p={3} style={{paddingLeft:0, paddingRight: 0}}>
              {children}
            </Box>
          )}
        </div>
      );
}

export default TabPanel;
