import React from 'react';
import { FormControl, TextField, InputAdornment, Container } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useContext } from 'react';
import {AppContext} from '../context/Context';
import {actions} from '../context/RecordReducer';

const SearchForm = () => {
    const {state, dispatch} = useContext(AppContext);

    const updateSearchTerm = (event) => {
        const term = event.target.value;
        dispatch({ type: actions.SEARCH, payload: term });
    }

    return (
        <Container>
            <form>
                <FormControl fullWidth>
                    <TextField
                        id="search"
                        label="Search By Activity"
                        value={state.searchTerm}
                        onChange={updateSearchTerm}
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </form>
            <br/>
            <br/>
        </Container>
    )
}

export default SearchForm;