import React, { Fragment } from 'react';
import { Typography, Paper, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TimelineItem, TimelineDot, TimelineOppositeContent, TimelineSeparator, TimelineContent, TimelineConnector } from '@material-ui/lab';

const useStyles = makeStyles({
    root: {
        display: "flex",
        marginBottom: "2em",
    },
    paper: {
        padding: "1rem 2rem",
        marginBottom: "3rem"
    },
    dot: {
        width: "4rem",
        height: "4rem",
    },
    content: {
        display: "flex",
        flexDirection: "column"
    },
    media: {
        width: "30%",
        height: "100%",
        objectPosition: "0 0",
        paddingTop: "16px",
        paddingLeft: "10px",
    },
});

const Job = (props) => {
    const classes = useStyles();
    const { company, logo, smallLogo, jobTitle, dates, responsibilities } = props.job;

    return (
        <TimelineItem>
            {
                props.smallScreen
                    ?
                    <Fragment />
                    :
                    <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                            {dates}
                        </Typography>
                    </TimelineOppositeContent>
            }
            <TimelineSeparator>
                <TimelineDot style={{ backgroundColor: "transparent" }}>
                    <Avatar src={smallLogo} alt={company} className={classes.dot} />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "left" }}>
                <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                        {company}
                    </Typography>
                    <Typography variant="subtitle2">
                        {jobTitle}
                    </Typography>
                    <Typography>
                        {responsibilities}
                    </Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    );

}

export default Job;
