import LocalStore from '../../Utils/LocalStore';
import uuid from 'uuid/v4';

export const actions = {
    ADD_RECORD: "ADD_RECORD",
    DELETE_RECORD: "DELETE_RECORD",
    UPDATE_RECORD: "UPDATE_RECORD",
    SEARCH: "SEARCH",
    OPEN_FORM: "OPEN_FORM",
    CLOSE_FORM: "CLOSE_FORM"
}

const RecordReducer = (state, action) => {
    let newState;
    let newRecord;
    const localStore = new LocalStore('prtrack');
    switch (action.type) {
        case actions.ADD_RECORD:
            newRecord = {id: uuid(), ...action.payload};
            newState = {...state, recordData: [...state.recordData, newRecord]};
            //TODO1 it would be cool if we could do this via a listener/observer pattern
            localStore.update('recordData', newState.recordData);
            return newState;
        case actions.UPDATE_RECORD:
            newRecord = action.payload
            const newRecords = state.recordData.map((record) => {
                if(record.id === newRecord.id){
                    return newRecord;
                }
                return record;
            });

            newState = {...state, recordData: newRecords};
            localStore.update('recordData', newState.recordData);
            return newState;
        case actions.DELETE_RECORD:
            let record = action.payload;
            if(!record.id){
                throw new Error('Cannot delete record with empty ID field');
            }
            const otherRecords = state.recordData.filter((a) => {
                return a.id !== record.id;
            });
            newState = {...state, 'recordData': otherRecords};
            localStore.update('recordData', newState.recordData);
            return newState;
        
        //TODO seperate reducers that are composed?? I think that's a thing
        case actions.SEARCH:
            newState = {...state, 'searchTerm': action.payload}
            localStore.update('searchTerm', newState.searchTerm);
            return newState;
        case actions.OPEN_FORM:
            newState = {...state, 'openForm': action.payload}
            return newState;
        case actions.CLOSE_FORM:
            newState = {...state, 'openForm': null}
            return newState;
        default:
            return state;
    }
}

export default RecordReducer;
