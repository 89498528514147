import { Paper } from '@material-ui/core';
import React from 'react';
import {PieChart, Pie, Cell} from 'recharts';

const COLORS = ['#A0A0A0', '#404040'];
const RADIAN = Math.PI / 180;

export const PetPieChart = ({data}) => {
    
    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <PieChart height={200} width={200}>
            <Pie 
                cx={100}
                cy={100}
                outerRadius={90}
                data={data}
                label={renderCustomizedLabel}
                labelLine={false}
                dataKey="value"
                fill="#03C04A"
            >
                {
                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
            </Pie>
        </PieChart>
    )
};


export const PetLineChart = ({data}) => {

};


