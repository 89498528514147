import React from 'react';
import RecordList from './RecordList';
import AppDrawer from './AppDrawer';
import { ContextProvider } from './context/Context';
import Heading from './Heading';

const App = (props) => {

    return (
        <ContextProvider>
            <Heading />
            <RecordList></RecordList>
            <AppDrawer></AppDrawer>
        </ContextProvider>
    );
}

export default App;
