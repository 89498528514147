import React from 'react';
import { PrTrackerApp } from '../PrTrackerApp';
import {AppWrapper} from './Wrappers';

const PrTracker = (props) => {
    return (
        <AppWrapper>
            <PrTrackerApp />
        </AppWrapper>
    );
}

export default PrTracker;
