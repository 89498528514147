import React from 'react';
import { PetWeightApp } from '../PetWeight';
import {AppWrapper} from './Wrappers';

const PetWeight = (props) => {
    return (
        <AppWrapper>
            <PetWeightApp />
        </AppWrapper>
    );
}

export default PetWeight;
