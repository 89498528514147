import React, {useContext} from 'react';
import { AppBar, Toolbar, IconButton, Link, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {GlobalContext} from '../context/AppState';
import useStyles from './useStyles';

const Logo = () => {
    const classes = useStyles('header');

    return (
        <Link className={classes.title} href="/" color="inherit" underline="none">
            <Typography variant="h4">DanRenaud.Me</Typography>
        </Link>
    );
}

const AccountLink = ({isLoggedIn, logout}) => {
    return (
        <Typography align="right">
            {
                isLoggedIn
                ? <Link onClick={logout} color="inherit">Logout</Link>
                : <Link color="inherit" href="/githublogin">Login</Link> 
            }
        </Typography>

    );
}

const Header = (props) => {
    const {state, dispatch} = useContext(GlobalContext);
    const classes = useStyles('header');

    const toggleMenu = () => {
        dispatch({
            type: "TOGGLEMENU"
        });
    }

    const logout = () => {
        dispatch({
            type: "LOGOUT"
        });
    }

    return (
        <React.Fragment>
            <AppBar>
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} onClick={toggleMenu} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Logo />
                    <AccountLink isLoggedIn={state.isLoggedIn} logout={logout}/>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </React.Fragment>
    );
}

export default Header;
