import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import { PetPieChart } from './Charts';
import {roundNumber} from '../Utils';

const PetDetails = ({pet}) => {
    const [state, setState] = useState({weightIndex: 0, personHeight: "200px", });
    
    let personHeight = 200;
    let petHeight = 50;
    let petImg = `images/petweight/${pet.type === 'cat' ? 'cat-solid' : 'dog-solid'}.svg`;


    let getPetWeight = () => {
        if(pet.weights && pet.weights[state.weightIndex]) {
            return roundNumber(pet.weights[state.weightIndex].petWeight);
        }
        return 0;
    }

    let getPersonWeight = () => {
        if(pet.weights && pet.weights[state.weightIndex]) {
            return roundNumber(pet.weights[state.weightIndex].personWeight);
        }
        return 0;
    }

    let getTotalWeight = () => {
        if(pet.weights && pet.weights[state.weightIndex]){
            return roundNumber(pet.weights[state.weightIndex].totalWeight);
        }
        return 0;
    }

    if(getPersonWeight()){
        //TODO these aren't calculating the way I want
        personHeight = getPersonWeight() > 200 ? 200 : getPersonWeight();
        petHeight = getPersonWeight() * (getPetWeight() / getPersonWeight());
    }

    useEffect(() => {
        if(pet.weights && pet.weights.length){
            setState({...state, weightIndex: pet.weights.length - 1});
        }

    }, [pet.weights]);

    return (
        <Grid container>
            <Grid item xs={12} align="center">
                <Typography variant="h5">{pet.name || 'Pet'} Details</Typography>
            </Grid>
            <Grid item xs={6}>
                <Select 
                    className="form-control buffer"
                    value={state.weightIndex}
                    onChange={(e) => setState({...state, weightIndex : e.target.value})}
                >
                    {
                        pet.weights && pet.weights.length
                        ? pet.weights.map((w, i) => {
                            return (
                            <MenuItem key={i} value={i}>{moment(w.createdTime).format('ddd. MMM DD, YYYY')}</MenuItem>
                            );
                        })
                        : <MenuItem key={0} value={0}>-- No Records --</MenuItem>
                    }
                </Select>
            </Grid>
            <Grid item xs={6} align="center" className="buffer">
                <Typography variant="subtitle1">
                    {`${getTotalWeight()}lbs - ${getPersonWeight()}lbs = ${getPetWeight()}lbs`}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={6}>
                        <img 
                            src="images/petweight/male-solid.svg"
                            height={Math.max(personHeight, 200)}
                            style={{display: 'inline-block'}}
                        />
                    </Grid>
                    <Grid item xs={6} style={{marginTop: 'auto'}}>
                        <img
                            src={petImg}
                            height={Math.max(petHeight, 30)}
                        />                        
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">{ getPersonWeight() } lbs</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">{ getPetWeight() } lbs</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} align="right">
                <PetPieChart
                    data = {[
                        {name: 'You', value: getPersonWeight()},
                        {name: pet.name, value: getPetWeight()}
                    ]}
                />
            </Grid>
        </Grid>
    );
}

export default PetDetails;