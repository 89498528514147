import React, { useContext, useState, useEffect } from 'react';
import {GlobalContext} from '../context/AppState';
import {PageWrapper} from './Pages/Wrappers';
import { Avatar, Card, CardContent, Link, Paper, Typography } from '@material-ui/core';
import GitHubIcon from '@material-ui/icons/GitHub';

const GithubLogin = () => {
  const {state, dispatch} = useContext(GlobalContext);
  const [data, setData] = useState({ errorMessage: "", isLoading: false });
  const { client_id, redirect_uri, client_secret } = state.auth;
  const oauth_url = state.dev_mode
    ? process.env.REACT_APP_API_HOST + '/dev/login/github'
    : process.env.REACT_APP_API_HOST + '/login/github';

  useEffect(() => {
      // After requesting Github access, Github redirects back to your app with a code parameter
      const url = window.location.href;
      const hasCode = url.includes("?code=");
  
      // If Github API returns the code parameter
      if (hasCode) {
        const newUrl = url.split("?code=");
        window.history.pushState({}, null, newUrl[0]);
        setData({ ...data, isLoading: true });
  
          const requestData = {
          client_id,
          redirect_uri,
          client_secret,
          code: newUrl[1]
        };

        // Use code parameter and other parameters to make POST request to proxy_server
        fetch(oauth_url, {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestData)
        })
          .then(response => response.json())
          .then(data => {
            dispatch({
              type: "LOGIN",
              payload: { user: data, isLoggedIn: true }
            });
          })
          .catch(error => {
            setData({
              isLoading: false,
              errorMessage: "Sorry! Login failed"
            });
          });
      }
    }, [state, dispatch, data]);
  

  const notLoggedIn = () => {
    return (
      <React.Fragment>
        <Typography variant="h3"><GitHubIcon fontSize="large"/> Login with GitHub</Typography>
        <a
          className="login-link"
          href={`https://github.com/login/oauth/authorize?scope=user&client_id=${client_id}&redirect_uri=${redirect_uri}`}
          onClick={() => {
            setData({ ...data, errorMessage: "" });
          }}
        >
          <GitHubIcon />
          <span>Login with GitHub</span>
        </a>
      </React.Fragment>
    );
  }

  const loggedIn = () => {
    const user = state.user.github.userData;
    return (
      <CardContent>
        <Avatar src={user.avatar_url} alt={user.name}/>
        <Typography variant="subtitle1">
          <Link href={user.html_url} target="_blank" rel="noreferrer">
            {user.name}
          </Link>
        </Typography>
      </CardContent>
    );
  }

  return (
    <PageWrapper>
        <Paper>
          <Card>
            {state.isLoggedIn ? loggedIn() : notLoggedIn()}
          </Card>
        </Paper>
    </PageWrapper>
  );
}

export default GithubLogin;