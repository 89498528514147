import { Button, Grid, TextField, InputAdornment } from '@material-ui/core';
import React, { useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';

const defaultWeight = {
    personWeight: "",
    petWeight: "",
    totalWeight: ""
};


const WeightForm = ({submitAction}) => {
    const [state, setState] = useState(defaultWeight);

    const handleChange = (event) => {
        let {name, value} = event.target;
        setState({...state, [name]: value})
    }

    const handleSave = () => {
        if (submitAction(state) ) {
            setState(defaultWeight);
        }
    }

    const isFormValid = () => {
        // check required
        if(!state.totalWeight || !state.personWeight){
            return false;
        }
        //check total weight more than person
        if (state.personWeight <= 0 || state.personWeight >= state.totalWeight){
            return false;
        }
        return true;
    }

    return (
        <Grid container spacing={0} justify="center">
            <Grid item xs={4}>
                <TextField
                    className="form-control"
                    label="You"
                    name="personWeight"
                    onChange={handleChange}
                    type="number"
                    placeholder="0"
                    value={state.personWeight}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={4}>
                <TextField
                    className="form-control"
                    label="Combined"
                    name="totalWeight"
                    onChange={handleChange}
                    type="number"
                    placeholder="0"
                    value={state.totalWeight}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={2} style={{margin: 'auto'}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSave}
                    disabled={!isFormValid()}
                    startIcon={<SaveIcon />}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
}

export default WeightForm;
