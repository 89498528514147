import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import {useTheme } from '@material-ui/core/styles';
import jobs from './jobs';
import Job from './Job';
import { Timeline } from '@material-ui/lab';

const JobList = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Timeline align={isSmall ? "left" : "alternate"}>
            {jobs.map((job) => {
                return <Job job={job} key={job.company} smallScreen={isSmall}/>;
            })}
        </Timeline>
    )
}

export default JobList;