import React, { useState, useContext, useEffect, Fragment } from 'react';
import { TextField, InputLabel, FormControl, Select, MenuItem, Grid, Typography, Button } from '@material-ui/core';
import { actions } from '../context/RecordReducer';
import { Actions, AppContext } from '../context/Context';
import useStyles from '../../useStyles';
import * as moment from 'moment';
import HistoryForm from './HistoryForm';

/**
 * Form for creating new PR activity
 */
const ActivityForm = (props) => {
    const [record, setRecord] = useState(props.record);
    const {state, dispatch} = useContext(AppContext);
    const classes = useStyles('createForm');
    const title = props.record.id ? props.record.activity : "New Activity"
    

    const handleChange = (event) => {
        const field = event.target.name;
        const value = event.target.value;

        //Special handling for first history entry (TODO improve this code)
        if (field === 'record') {
            setRecord({ ...record, history: [{ value: value, date: record.history[0].date }] });
        } else if (field === 'record-date') {
            setRecord({ ...record, history: [{ value: record.history[0].value, date: value }] });
        } else {
            setRecord({ ...record, [field]: value });
        }
    }

    const handleCancel = () => {
        setRecord(props.record);
        dispatch({type: Actions.CLOSE_FORM});
    }

    const handleSave = () => {
        if(record.id){
            dispatch({type: Actions.UPDATE_RECORD, payload: record});
        } else {
            dispatch({type: Actions.ADD_RECORD, payload: record});
        }
        dispatch({type: Actions.CLOSE_FORM});
    }

    return (
        <form>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
                <Grid item xs={3} align="right" onClick={handleCancel}>
                    <Button>Cancel</Button>
                </Grid>
                <Grid item xs={2} align="right" onClick={handleSave}>
                    <Button>Save</Button>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="dense" size="small" className={classes.control}>
                        <TextField
                            id="activity"
                            name="activity"
                            label="Activity"
                            value={record.activity}
                            onChange={handleChange}
                            size="small"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <UnitSelector
                            updateUnit={(unit, unitType) => {
                                setRecord({ ...record, unit, unitType });
                            }}
                            record={record}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl margin="dense" size="small">
                        <TextField
                            type="number"
                            id="goal"
                            name="goal"
                            label="Goal"
                            value={record.goal}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Grid>
                {
                   !record.id ? 
                        <>
                        <Grid item xs={6}>
                            <FormControl margin="dense" size="small">
                                <TextField
                                    type="number"
                                    id="record"
                                    name="record"
                                    label="Current PR"
                                    value={record.history && record.history.length ? record.history[0].value : 0}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl margin="dense" size="small">
                                <TextField
                                    id="record-date"
                                    name="record-date"
                                    label="Date Achieved"
                                    type="date"
                                    value={record.history && record.history.length ? record.history[0].date : moment().format('yyyy-MM-DD')}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                />
                            </FormControl>
                        </Grid>
                        </>
                : ''
                }
            </Grid>
        </form>
    )
}

/**
 * Form for create a new PR activity
 */
export const CreateActivityForm = (props) => {
    const today = moment().format('yyyy-MM-DD');
    const recordTemplate = {
        activity: "",
        unitType: "weight",
        unit: "lbs",
        goal: "",
        history: [
            { value: "", date: today }
        ]
    };

    return (
        <ActivityForm record={recordTemplate}/>
    );
}


/**
 * Form for editing a PR activity
 */
export const EditActivityForm = ({ record }) => {
    return (
        <ActivityForm record={record}/>
    );
}

const UnitSelector = ({ updateUnit, record }) => {
    //TODO get this from datastore
    const unitTypes = [
        { key: 'weight', label: 'Weight' },
        { key: 'reps', label: 'Reps' },
        { key: 'speed', label: 'Speed' }
    ];

    const units = {
        weight: [
            { key: 'lbs', label: 'Pounds' }, { key: 'kg', label: 'Kilos' }
        ],
        reps: [
            { key: 'reps', label: 'Reps' }
        ],
        speed: [
            { key: 'seconds', label: 'Seconds' }
        ]
    };

    const [selectedUnitType, setSelectedUnitType] = useState(record.unitType ?? 'weight');
    const [selectedUnit, setSelectedUnit] = useState(record.unit ?? 'lbs');

    const handleUnitTypeChange = (e) => {
        setSelectedUnitType(e.target.value);
        //Set to default unit for type
        setSelectedUnit(units[e.target.value][0].key);
    }

    const handleUnitChange = (e) => {
        setSelectedUnit(e.target.value);
    }

    useEffect(() => {
        updateUnit(selectedUnit, selectedUnitType);
    }, [selectedUnit, selectedUnitType])

    return (
        <Fragment>
            <Grid item xs={6} sm={4}>
                <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="select-unit-type">Unit Type</InputLabel>
                    <Select
                        labelId="select-unit-type"
                        id="unit-type"
                        name="unit_type"
                        value={selectedUnitType}
                        onChange={handleUnitTypeChange}
                    >
                        {unitTypes.map((unitType) => {
                            return (
                                <MenuItem
                                    value={unitType.key}
                                    key={unitType.key}
                                >
                                    {unitType.label}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={4}>
                <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="select-unit">Unit</InputLabel>
                    <Select
                        labelId="select-unit"
                        id="unit"
                        name="unit"
                        value={selectedUnit}
                        onChange={handleUnitChange}
                    >
                        {units[selectedUnitType].map((unit) => {
                            return (
                                <MenuItem value={unit.key} key={unit.key}>{unit.label}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </Fragment>
    )
}