import React from 'react';
import Resume from '../Resume/Resume';
import {PageWrapper} from './Wrappers';

const ResumePage = () => {
    return (
        <PageWrapper>
            <Resume />
        </PageWrapper>
    )
}

export default ResumePage;
